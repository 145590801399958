// import facebookIcon from '../images/facebook.svg'
import twitterIcon from '../images/twitter.svg'

export default [
  {
    label: 'Twitter',
    url: 'https://twitter.com/buffydotmoney',
    icon: twitterIcon
  }
  // { label: 'Facebook', url: 'https://facebook.com', icon: facebookIcon }
]
